import React, { useState } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import validation from "../../components/AlumnoValidation";

const UpdateAlumno = () => {
  // Estados para almacenar los valores del formulario
  const [nombreAlumno, setNombre] = useState("");
  const [edad, setEdad] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Crea un objeto alumnoData con propiedades cuyos valores
    // son extraídos de las variables nombreAlumno, edad, email y direccion
    const alumnoData = {
      nombreAlumno,
      edad,
      email,
      direccion,
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(alumnoData));

    // Verificar si no hay errores de validación
    if (
      nombreAlumno !== "" &&
      edad !== "" &&
      email !== "" &&
      direccion !== ""
    ) {
      // Enviar el formulario
      submitForm();
    }
  };

  const submitForm = async () => {
    try {
      // Realiza una solicitud PUT al backend para modificar el alumno
      await axios.put(`https://backend-8t9t.onrender.com/update/${id}`, {
        nombreAlumno,
        edad,
        email,
        direccion,
      });

      // Limpiar los campos del formulario
      setNombre("");
      setEdad("");
      setEmail("");
      setDireccion("");

      // Navegar a la página de listado de alumnos
      navigate("/alumnos");

      // Mostrar una alerta indicando que el alumno se modificó correctamente
      alert("Alumno modificado correctamente");
    } catch (error) {
      console.log(error);
      alert("Error al modificar el alumno");
    }
  };

  return (
    <Menu>
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Modificar alumno</h4>
          {/* Campo para el nombre */}
          <input
            className="controls"
            type="text"
            name="nombreAlumno"
            id="nombreAlumno"
            placeholder="Ingrese su Nombre"
            value={nombreAlumno}
            onChange={(e) => setNombre(e.target.value)}
          />
          <div className="errores">
            {errors.nombreAlumno && errors.nombreAlumno}
          </div>
          {/* Campo para la edad */}
          <input
            className="controls"
            type="number"
            name="edad"
            id="edad"
            placeholder="Ingrese su edad"
            value={edad}
            onChange={(e) => setEdad(e.target.value)}
          />
          <div className="errores">{errors.edad && errors.edad}</div>
          {/* Campo para el email */}
          <input
            className="controls"
            type="email"
            name="email"
            id="email"
            placeholder="Ingrese su Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="errores">{errors.email && errors.email}</div>
          {/* Campo para la dirección */}
          <input
            className="controls"
            type="text"
            name="direccion"
            id="direccion"
            placeholder="Ingrese su Direccion"
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
          />
          <div className="errores">
            {errors.direccion && errors.direccion}
          </div>
          {/* Botón para modificar */}
          <input className="botons" type="submit" value="Modificar" />
        </form>
      </section>
    </Menu>
  );
};

export default UpdateAlumno;
import React, { useState } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import validation from "../../components/VehiculoValidation";

const Updatevehiculo = () => {
  // Estados para almacenar los valores del formulario
  const [marca, setMarca] = useState("");
  const [ano, setAno] = useState("");
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();
  const { id } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();

    // Crea un objeto vehiculoData con propiedades cuyos valores
    // son extraídos de las variables marca y ano
    const vehiculoData = {
      marca,
      ano,
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(vehiculoData));

    // Verificar si no hay errores de validación
    if (marca !== "" && ano !== "") {
      // Enviar el formulario
      submitForm();
    }
  };

  const submitForm = async () => {
    try {
      // Realiza una solicitud PUT al backend para modificar el vehículo
      await axios.put(
        `https://backend-8t9t.onrender.com/updatevehiculo/${id}`,
        {
          marca,
          ano,
        }
      );

      // Limpiar los campos del formulario
      setMarca("");
      setAno("");

      // Navegar a la página de listado de vehículos
      navigate("/vehiculos");

      // Mostrar una alerta indicando que el vehículo se modificó correctamente
      alert("Vehículo modificado correctamente");
    } catch (error) {
      console.log(error);
      alert("Error al modificar el vehículo");
    }
  };

  return (
    <Menu>
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Modificar Vehículo</h4>
          {/* Campo para la marca del vehículo */}
          <input
            className="controls"
            type="text"
            name="marca"
            id="marca"
            placeholder="Ingrese la marca"
            value={marca}
            onChange={(e) => setMarca(e.target.value)}
          />
          <div className="errores">{errors.marca && errors.marca}</div>
          {/* Campo para el año del vehículo */}
          <input
            className="controls"
            type="number"
            name="ano"
            id="ano"
            placeholder="Ingrese el año"
            value={ano}
            onChange={(e) => setAno(e.target.value)}
          />
          <div className="errores">{errors.ano && errors.ano}</div>
          {/* Botón para modificar */}
          <input className="botons" type="submit" value="Modificar" />
        </form>
      </section>
    </Menu>
  );
};

export default Updatevehiculo;
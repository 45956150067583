//importamos los modulos necesarios
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import axios from "axios";

const Profesores = () => {
  // Estado para almacenar los datos de los profesores
  const [data, setData] = useState([]);

  // Se ejecuta al cargar el componente
  useEffect(() => {
    // Realiza una solicitud GET al backend para obtener los profesores
    axios
      .get("https://backend-8t9t.onrender.com/profesores")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);
  /* Representamos la tabla a traves de JSX con su respectiva estructura */
  return (
    <div className="table-responsive">
      <Menu>
        <h1 className="profesores">
          Profesores<br></br>
        </h1>
        <table className="table2">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Email</th>
              <th>Clase</th>
            </tr>
          </thead>
          <tbody>
            {/* Utiliza  la función `map` para iterar sobre
               la matriz `data` y crear una nueva matriz de elementos JSX.*/}
            {data.map((d, i) => (
              <tr key={i}>
                <td data-titulo="nombre">{d.nombreProfesor}</td>
                <td data-titulo="email">{d.email}</td>
                <td data-titulo="clase">{d.especialidad}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </Menu>
    </div>
  );
};

export default Profesores;

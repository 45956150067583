// importamos los modulos necesrios y los controladores para definir las rutas
import React from "react";
import Login from "./pages/Login";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Signup from "./pages/Signup";
import Menu from "./components/Menu";
import Alumnos from "./pages/alumnos/Alumnos"
import Profesores from "./pages/profesores/Profesores";
import Vehiculos from "./pages/vehiculos/Vehiculos";
import About from "./pages/About";
import Clases from "./pages/clases/Clases";
import CreateAlumno from "./pages/alumnos/CreateAlumno";
import UpdateAlumno from "./pages/alumnos/UpdateAlumno";
import CreateClases from "./pages/clases/CreateClases";
import UpdateClases from "./pages/clases/UpdateClases";
import Anadirvehiculos from "./pages/vehiculos/Anadirvehiculos";
import Updatevehiculo from "./pages/vehiculos/Updatevehiculo";

const App = () => {
  return (
    //configuracion de enrutamiento con react router
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Login />} />
        <Route path="/signup" element={<Signup />} />
        <Route path="/menu" element={<Menu />} />
        <Route path="/about" element={<About />} />
        <Route path="/alumnos" element={<Alumnos />} />
        <Route path="/create" element={<CreateAlumno />} />
        <Route path="/update/:id" element={<UpdateAlumno />} />
        <Route path="/profesores" element={<Profesores />} />
        <Route path="/clases" element={<Clases />} />
        <Route path="/Anadirclases" element={<CreateClases />} />
        <Route path="/editclases/:id" element={<UpdateClases />} />
        <Route path="/vehiculos" element={<Vehiculos />} />
        <Route path="/Anadirvehiculos" element={<Anadirvehiculos />} />
        <Route path="/updatevehiculo/:id" element={<Updatevehiculo />} />
      </Routes>
    </BrowserRouter>
  );
};

export default App;

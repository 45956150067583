/**
 * La función `validación` valida el
 * formulario verificando si ciertos campos están vacíos o si el campo
 *  de correo electrónico tiene el formato correcto. si esta vacio devolvera un mensaje
 * con el error
 *
 */
function validation(values) {
  let error = {};
  //Definimos la expresion regular para validar el formato del correo electronico
  const email2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Si el valor del campo email dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.email indicando que el email no puede estar vacío.
  if (values.email === "") {
    error.email = "El email no puede estar vacio";
  } else if (!email2.test(values.email)) {
    error.email = "No encontramos el email";
  } else {
    error.email = "";
  }
  // Si el valor del campo nombre dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.nombre indicando que el nombre no puede estar vacío.
  if (values.nombreProfesor === "") {
    error.nombreProfesor = "El nombre no puede estar vacio";
  } else {
    error.nombreProfesor = "";
  }
  // Si el valor del campo contrasena dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.contrasena indicando que la contrasena no puede estar vacía.
  if (values.contrasena === "") {
    error.contrasena = "Escriba su contraseña";
  } else {
    error.contrasena = "";
  }
  // Si el valor del campo especialidad dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.especialidad indicando que la especialidad no puede estar vacía.
  if (values.especialidad === "") {
    error.especialidad = "La especialidad no puede estar vacia";
  } else {
    error.especialidad = "";
  }

  return error;
}

export default validation;

/* Importamos los modulos y los componentes de las bibliotecas correspondientes
asi como React y axios*/

import React, { useContext, useEffect } from "react";
import { useState } from "react";
import Menu from "../components/Menu";
import { NavLink } from "react-router-dom";
import { UserContext } from "../UserContext";
import axios from "axios";

/* En el about, se encuentran 3 variables de estado: "numAlumnos", "numProfesores" y "numClases",
que se inicializan con el valor de 0 usando el hook useState. */

const About = () => {
  const [numAlumnos, setNumAlumnos] = useState(0);
  const [numProfesores, setNumProfesores] = useState(0);
  const [numClases, setNumClases] = useState(0);

  /* `useEffect` los usamos para obtener datos del backend cuando se monta el componente. */
  useEffect(() => {
    fetchData();
  }, []);

  /**
   * La función fetchData obtiene datos de tres puntos diferentes estableciendo con lenght
   * la cantidad de alumnos, profesores y clases en función de los datos de respuesta.
   */
  const fetchData = async () => {
    try {
      const responseAlumnos = await axios.get(
        "https://backend-8t9t.onrender.com/alumnos"
      );
      setNumAlumnos(responseAlumnos.data.length);

      const responseProfesores = await axios.get(
        "https://backend-8t9t.onrender.com/profesores"
      );
      setNumProfesores(responseProfesores.data.length);

      const responseClases = await axios.get(
        "https://backend-8t9t.onrender.com/clases"
      );
      setNumClases(responseClases.data.length);
    } catch (error) {
      console.error("Error:", error);
    }
  };

  /* Utilizamos el UserContext para acceder a los
  `user` y `loggedIn` de él. */

  const { user, loggedIn } = useContext(UserContext);

  /* Inicializa una variable de estado llamada 
    `cards`usando el gancho `useState` y le da su titulo, imagen y texto. */

  const [cards] = useState([
    {
      title: "Alumnos",
      image: "/img/alumnos2.png",
      text1: "Alumnos",
    },
    {
      title: "Profesores",
      image: "/img/profesores2.png",
      text2: "profesores",
    },
    {
      title: "Clases",
      image: "/img/clases.png",
      text3: "clases",
    },
  ]);
  return (
    /* Comprobamos que usuario esta logeado para darle la bienvenida recogiendo de la BDD
        su email*/
    <div>
      <section>
        <Menu>
          {loggedIn && user && (
            <h2>
              Bienvenido: <br></br>
              {user.email}
            </h2>
          )}
          {/* Definimos un classname llamado container-cards donde recogerá las variables de
        numAlumnos, numProfesores y numClases para decir la cantidad de registros que hay en la BDD 
        con el .lenght */}
          <br></br>
          <div className="container-cards">
            <div className="cards">
              <div className="card">
                <h2> Alumnos </h2>

                <p>{numAlumnos}</p>
              </div>
              <div className="card">
                <h2> Profesores</h2>

                <p>{numProfesores}</p>
              </div>
              <div className="card">
                <h2> Clases</h2>

                <p>{numClases}</p>
              </div>
            </div>
          </div>
          <br></br>
          <br></br>

          {/* Representamos un conjunto de tarjetas con sus títulos, imágenes y
            botones.*/}
          <div className="container-cards">
            <br></br>

            <div className="cards2">
              {cards.map((card, i) => (
                <div key={i} className="card">
                  <h3>{card.title}</h3>
                  <img src={card.image} alt="autoescuelaabril" />

                  {/* Representamos los botones de las cards segun su valor:
                   `card.text1`, `card.text2` y `card.text3`.  */}

                  <div className="button-container">
                    {card.text1 ? (
                      <>
                        <NavLink className="btn-cards btn-1" to="/alumnos">
                          Listado
                        </NavLink>
                        <NavLink className="btn-cards btn-2" to="/create">
                          Añadir
                        </NavLink>
                      </>
                    ) : card.text2 ? (
                      <>
                        <NavLink className="btn-cards btn-1" to="/profesores">
                          Listado
                        </NavLink>
                        <NavLink className="btn-cards btn-2" to="">
                          Añadir
                        </NavLink>
                      </>
                    ) : card.text3 ? (
                      <>
                        <NavLink className="btn-cards btn-1" to="/clases">
                          Listado
                        </NavLink>
                        <NavLink className="btn-cards btn-2" to="/Anadirclases">
                          Añadir
                        </NavLink>
                      </>
                    ) : (
                      <p>No existe.</p>
                    )}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </Menu>
      </section>
    </div>
  );
};

export default About;

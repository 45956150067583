//Importamos los modulos necesarios
import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { useNavigate, useParams } from "react-router-dom";
import validation from "../../components/ClasesValidation";

const UpdateClases = () => {
  // Estados para almacenar los valores del formulario
  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");
  const [alumno_id, setAlumnoId] = useState("");
  const [alumnos, setAlumnos] = useState([]);
  const [profesores, setProfesores] = useState([]);
  const [profesor_id, setProfesorId] = useState("");
  const [vehiculos, setVehiculos] = useState([]);
  const [vehiculo_id, setVehiculoId] = useState("");
  const [errors, setErrors] = useState({});

  // Hook useNavigate para la navegación programática
  const navigate = useNavigate();

  // Obtener el parámetro de la URL
  const { id } = useParams();

  const handleSubmit = (event) => {
    event.preventDefault();
    // Realiza una solicitud PUT al backend para modificar la clase

    // Datos de la clase a enviar al backend
    const claseData = {
      fecha,
      hora,
      alumno_id,
      profesor_id,
      vehiculo_id,
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(claseData));
    // Verificar si no hay errores de validación
    if (
      fecha !== "" &&
      hora !== "" &&
      alumno_id !== "" &&
      profesor_id !== "" &&
      vehiculo_id !== ""
    ) {
      submitForm();
    }
  };
  const submitForm = async () => {
    try {
      // Realiza una solicitud PUT al backend para modificar las clases
      await axios.put("https://backend-8t9t.onrender.com/editclases/" + id, {
          fecha,
          hora,
          alumno_id,
          profesor_id,
          vehiculo_id,
        });
      //limpiar el formulario
      setFecha("");
      setHora("");
      setAlumnoId("");
      setProfesorId("");
      setVehiculoId("");

      navigate("/clases");
      alert("Clase modificada correctamente");
    } catch (error) {
      console.log(error);
      alert("Error al modificar la clase");
    }
  };
  useEffect(() => {
    // Obtener la lista de alumnos desde el backend
    axios
      .get("https://backend-8t9t.onrender.com/alumnos")
      .then((res) => setAlumnos(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Obtener la lista de profesores desde el backend
    axios
      .get("https://backend-8t9t.onrender.com/profesores")
      .then((res) => setProfesores(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    // Obtener la lista de vehículos desde el backend
    axios
      .get("https://backend-8t9t.onrender.com/vehiculos")
      .then((res) => setVehiculos(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleAlumnoChange = (e) => {
    setAlumnoId(e.target.value); // Almacenar el ID del alumno seleccionado
  };

  const handleProfesorChange = (e) => {
    setProfesorId(e.target.value); // Almacenar el ID del profesor seleccionado
  };

  const handleVehiculoChange = (e) => {
    setVehiculoId(e.target.value); // Almacenar el ID del vehículo seleccionado
  };

  return (
    <Menu>
      {/* Formulario para modificar una clase existente*/}
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Modificar Clase</h4>
          {/* Campo para la fecha */}
          <input
            className="controls"
            type="date"
            name="fecha"
            id="fecha"
            placeholder="Ingrese la fecha"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
          />
          <div className="errores">{errors.fecha && errors.fecha}</div>
          {/* Campo para la hora */}
          <input
            className="controls"
            type="time"
            name="hora"
            id="hora"
            placeholder="Ingrese la hora"
            value={hora}
            onChange={(e) => setHora(e.target.value)}
          />
          <div className="errores">{errors.hora && errors.hora}</div>

          {/* Select para seleccionar el alumno */}
          <select
            className="controls"
            name="alumno_id"
            id="alumno_id"
            value={alumno_id}
            onChange={handleAlumnoChange}
          >
            <option value="">Seleccionar alumno</option>
            {alumnos.map((alumno) => (
              <option key={alumno.id} value={alumno.id}>
                {alumno.nombreAlumno}
              </option>
            ))}
          </select>
          <div className="errores">{errors.alumno_id && errors.alumno_id}</div>
          {/* Select para seleccionar el profesor */}
          <select
            className="controls"
            name="profesor_id"
            id="profesor_id"
            value={profesor_id}
            onChange={handleProfesorChange}
          >
            <option value="">Seleccionar profesor</option>
            {profesores.map((profesor) => (
              <option key={profesor.id} value={profesor.id}>
                {profesor.nombreProfesor}
              </option>
            ))}
          </select>
          <div className="errores">
            {errors.profesor_id && errors.profesor_id}
          </div>
          {/* Select para seleccionar el vehículo */}
          <select
            className="controls"
            name="vehiculo_id"
            id="vehiculo_id"
            value={vehiculo_id}
            onChange={handleVehiculoChange}
          >
            <option value="">Seleccionar vehículo</option>
            {vehiculos.map((vehiculo) => (
              <option key={vehiculo.id} value={vehiculo.id}>
                {vehiculo.marca}
              </option>
            ))}
          </select>
          <div className="errores">
            {errors.vehiculo_id && errors.vehiculo_id}
          </div>
          {/* Botón para modificar */}
          <input className="botons" type="submit" value="Modificar" />
        </form>
      </section>
    </Menu>
  );
};

export default UpdateClases;

//importamos los modulos necesarios
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Vehiculos = () => {
  // Estado para almacenar los datos de los vehículos
  const [data, setData] = useState([]);

  useEffect(() => {
    // Realiza una solicitud GET al backend para obtener los vehículos
    axios
      .get("https://backend-8t9t.onrender.com/vehiculos")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  }, []);

  const navigate = useNavigate();

  // Maneja el evento de clic en el botón "Borrar" de un vehículo
  const handleDelete = (id) => {
    axios
      .delete("https://backend-8t9t.onrender.com/deletevehiculos/" + id)
      .then((res) => navigate("/vehiculos")) // Navega a la página de vehículos después de borrar uno
      .catch((err) => console.log(err));
  };

  return (
    /*Creamos una tabla con la lista de todos los vehiculos*/
    <div className="table-responsive">
      <Menu>
        <h1 className="vehiculos">
          Vehículos
          <br></br>
          <Link className="btn-a" to="/Anadirvehiculos">
            Añadir +
          </Link>
        </h1>
        <table className="table">
          <thead>
            <tr>
              <th>Marca</th>
              <th>Año</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr key={i}>
                <td data-titulo="Marca">{d.marca}</td>
                <td data-titulo="Año">{d.ano}</td>
                <td>
                  {/* Enlace para modificar el vehículo */}
                  <Link to={`/updatevehiculo/${d.id}`} className="btn-m">
                    Modificar
                  </Link>
                  {/* Enlace para borrar el vehículo */}
                  <Link onClick={(e) => handleDelete(d.id)} className="btn-b">
                    Borrar
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Menu>
    </div>
  );
};

export default Vehiculos;

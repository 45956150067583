//importamos los modulos necesarios
import React, { useState, useContext } from "react";
import { Link, useNavigate } from "react-router-dom";
import validation from "../components/LoginValidation";
import axios from "axios";

import { UserContext } from "../UserContext";

const Login = () => {
  // Contexto de usuario
  const { setUser, setLoggedIn } = useContext(UserContext);
  // Estados para almacenar los valores del formulario y los errores
  const [values, setValues] = useState({
    email: "",
    contrasena: "",
  });
  const [errors, setErrors] = useState({});

  const navigate = useNavigate();

  const handleInput = (event) => {
    setValues((prev) => ({ ...prev, [event.target.name]: event.target.value }));
  };

  const handleSubmit = async(event) => {
    event.preventDefault();
    setErrors(validation(values));
    
    if (errors.email === "" && errors.contrasena === "") {
      try{
      // Enviamos una solicitud POST al backend para realizar el inicio de sesión
      const res = await axios.post("https://backend-8t9t.onrender.com/login", values);
        
      if (res.data === "Success") {
        setUser(values);
        setLoggedIn(true);
        navigate("/about"); // Redireccionamos al usuario a la página "about"
      } else {
        alert("No existe ningún registro"); //Mensaje de alerta sino encuentra el registro
      }
    } catch (err) {
      console.log(err);
    }
  }
};

  return (
    //Creamos el formulario con sus respectivos campos que haran que se inicie sesion
    <div className="login-container">
      <div className="login-info-container">
        <br></br>
        <br></br>
        <h1 className="title">Iniciar Sesión</h1>
        <form action="" className="inputs-container" onSubmit={handleSubmit}>
          <br></br>
          <label htmlFor="email" className="label">
            Insertar email:
          </label>
          <br></br>
          <input
            type="text"
            placeholder="Escribe tu email"
            name="email"
            onChange={handleInput}
            className="form-control"
          />
          <div className="errores">{errors.email && errors.email}</div>
          <br></br>
          <br></br>
          <label htmlFor="contrasena" className="label">
            Insertar contraseña:
          </label>
          <br></br>
          <input
            type="password"
            placeholder="Enter contrasena"
            name="contrasena"
            onChange={handleInput}
            className="form-control"
            autocomplete="off"
          />
          <div className="errores">
            {errors.contrasena && errors.contrasena}
          </div>
          <br></br>
          <br></br>
          <button type="submit" className="btn">
            Inicia Sesion
          </button>
          <br></br>
          <Link to="/signup" className="btn2">
            Crear cuenta
          </Link>
        </form>
      </div>
      <img className="image-container" src="/img/profesores.png" alt=""></img>
    </div>
  );
};

export default Login;

// Importamos los modulos necesarios
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import './style/index.css';
import { UserProvider } from './UserContext';

// Crea un contenedor de renderizado raíz en el elemento con el id 'root'
const root = ReactDOM.createRoot(document.getElementById('root'));

// Renderiza la aplicación dentro del proveedor de contexto de usuario
root.render(
  <UserProvider>
    <App />
  </UserProvider>
);

/* Importamos los módulos y componentes necesarios para 
 Alumnos. */

import React, { useEffect, useState } from "react";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";
import Menu from "../../components/Menu";

const Alumnos = () => {
  /* Utilizamos los ganchos de `useState` y `useEffect` 
   de React para obtener los datos del backend y
   actualizar el estado del componente. */

  const [data, setData] = useState([]);

  /* Realizamos una solicitud HTTP GET a la URL especificada
   (`https://backend-8t9t.onrender.com/alumnos`) utilizando la biblioteca `axios`. */

  useEffect(() => {
    axios
      .get("https://backend-8t9t.onrender.com/alumnos")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  });

  /* Se usa el gancho de `useNavigate` de la
       biblioteca `react-router-dom` para obtener una función que nos permita navegar a diferentes
       rutas en nuestra aplicación. */
  const navigate = useNavigate();

  /*La función handleDelete envía una solicitud DELETE a una URL específica (`https://backend-8t9t.onrender.com/delete/`) 
         con la ID dada y luego navega a una página diferente.
         */
  const handleDelete = (id) => {
    axios
      .delete("https://backend-8t9t.onrender.com/delete/" + id)
      .then((res) => navigate("/alumnos"))
      .catch((err) => console.log(err));
  };
  return (
    /* Representamos la tabla a traves de JSX con su respectiva estructura */
    <div className="table-responsive">
      <Menu>
        {/* Navega a la ruta /create cuando se hace clic. El texto Añadir + se muestra 
        dentro del componente Enlace, creando una apariencia similar a un botón para agregar
        nuevos alumnos. */}

        <h1 className="alumnos">
          Alumnos<br></br>
          <Link className="btn-a" to="/create">
            Añadir +
          </Link>
        </h1>

        <table className="table2">
          <thead>
            <tr>
              <th>Nombre</th>
              <th>Edad</th>
              <th>Email</th>
              <th>Direccion</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {/* Utiliza  la función `map` para iterar sobre
               la matriz `data` y crear una nueva matriz de elementos JSX.*/}
            {data.map((d, i) => (
              <tr>
                <td data-titulo="nombre">{d.nombreAlumno}</td>
                <td data-titulo="edad">{d.edad}</td>
                <td data-titulo="email">{d.email}</td>
                <td data-titulo="direccion">{d.direccion}</td>
                <td>
                  {/* Crea un enlace que navega a la ruta cuando se hace clic. 
                           El `d.id` es la identificación del
                           alumno, donde navega a la página de actualización (UpdateAlumnos)
                           para ese alumno (ID).*/}
                  <Link to={`/update/${d.id}`} className="btn-m">
                    Modificar
                  </Link>

                  {/* Crea un elemento de enlace que, al hacer clic en él, ejecutará la función `handleDelete` con
                            `d.id` como argumento. Esta función es responsable de enviar una
                            solicitud de ELIMINACIÓN a una URL específica con la ID dada, en este caso para eliminar
                            a un alumno especifico (ID).*/}
                  <Link onClick={(e) => handleDelete(d.id)} className="btn-b">
                    Borrar
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Menu>
    </div>
  );
};

export default Alumnos;

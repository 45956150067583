// Importamos los módulos y componentes necesarios
import React, { useState, useEffect } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import validation from "../../components/ClasesValidation";

const CreateClases = () => {
  // Estados para almacenar los valores del formulario
  const [alumnos, setAlumnos] = useState([]);
  const [alumno_id, setAlumnoId] = useState("");

  const [profesores, setProfesores] = useState([]);
  const [profesor_id, setProfesorId] = useState("");

  const [fecha, setFecha] = useState("");
  const [hora, setHora] = useState("");

  const [vehiculos, setVehiculos] = useState([]);
  const [vehiculo_id, setVehiculoId] = useState("");

  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  // Obtener los datos de los alumnos, profesores y vehículos del backend
  useEffect(() => {
    axios
      .get("https://backend-8t9t.onrender.com/alumnos")
      .then((res) => setAlumnos(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("https://backend-8t9t.onrender.com/profesores")
      .then((res) => setProfesores(res.data))
      .catch((err) => console.log(err));
  }, []);

  useEffect(() => {
    axios
      .get("https://backend-8t9t.onrender.com/vehiculos")
      .then((res) => setVehiculos(res.data))
      .catch((err) => console.log(err));
  }, []);

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Datos de la clase a enviar al backend
    const claseData = {
      fecha,
      hora,
      alumno_id,
      profesor_id,
      vehiculo_id,
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(claseData));
    // Verificar si no hay errores de validación
    if (
      fecha !== "" &&
      hora !== "" &&
      alumno_id !== "" &&
      profesor_id !== "" &&
      vehiculo_id !== ""
    ) {
      submitForm();
    }
  };
    const submitForm = async () => {
      try {
        // Enviar el formulario a través de fetch
        const response = await fetch(
          "https://backend-8t9t.onrender.com/Anadirclases",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              fecha,
              hora,
              alumno_id,
              profesor_id,
              vehiculo_id
            }),
          }
        );

        if (response.ok) {
          // Limpiar los campos del formulario
          setFecha("");
          setHora("");
          setAlumnoId("");
          setProfesorId("");
          setVehiculoId("");

          navigate("/clases");
          alert("Clase añadida correctamente");
        } else {
          console.log("Error al enviar el formulario");
          alert("Error al enviar el formulario");
        }
      } catch (error) {
        console.log(error);
        alert("Error al enviar el formulario");
      }
    };

  return (
    <Menu>
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Añadir clase</h4>

          {/* Select para seleccionar el alumno */}
          <select
            className="controls"
            name="alumno_id"
            id="alumno_id"
            value={alumno_id}
            onChange={(e) => setAlumnoId(e.target.value)}
          >
            <option value="">Seleccionar alumno</option>
            {alumnos.map((alumno) => (
              <option key={alumno.id} value={alumno.id}>
                {alumno.nombreAlumno}
              </option>
            ))}
          </select>
          <div className="errores">{errors.alumno_id && errors.alumno_id}</div>

          {/* Select para seleccionar el profesor */}
          <select
            className="controls"
            name="profesor_id"
            id="profesor_id"
            value={profesor_id}
            onChange={(e) => setProfesorId(e.target.value)}
          >
            <option value="">Seleccionar profesor</option>
            {profesores.map((profesor) => (
              <option key={profesor.id} value={profesor.id}>
                {profesor.nombreProfesor}
              </option>
            ))}
          </select>
          <div className="errores">
            {errors.profesor_id && errors.profesor_id}
          </div>

          {/* Campo de entrada para la fecha */}
          <input
            className="controls"
            type="date"
            name="fecha"
            id="fecha"
            placeholder="Ingrese la fecha"
            value={fecha}
            onChange={(e) => setFecha(e.target.value)}
          />
          <div className="errores">{errors.fecha && errors.fecha}</div>
          {/* Campo de entrada para la hora */}
          <input
            className="controls"
            type="time"
            name="hora"
            id="hora"
            placeholder="Ingrese la hora"
            value={hora}
            onChange={(e) => setHora(e.target.value)}
          />
          <div className="errores">{errors.hora && errors.hora}</div>
          {/* Select para seleccionar el vehículo */}
          <select
            className="controls"
            name="vehiculo_id"
            id="vehiculo_id"
            value={vehiculo_id}
            onChange={(e) => setVehiculoId(e.target.value)}
          >
            <option value="">Seleccionar vehículo</option>
            {vehiculos.map((vehiculo) => (
              <option key={vehiculo.id} value={vehiculo.id}>
                {vehiculo.marca}
              </option>
            ))}
          </select>
          <div className="errores">
            {errors.vehiculo_id && errors.vehiculo_id}
          </div>
          {/* Botón de envío del formulario */}
          <input className="botons" type="submit" value="Añadir" />
        </form>
      </section>
    </Menu>
  );
};

export default CreateClases;

/**
 * Esta funcion de validation lo que hace es comprobar si los valores
 * del correo electronico y contraseña son validos, sino lo son devolverá
 * un objeto con el error
 */
function validation(values) {
  let error = {};
  //Definimos la expresion regular para validar el formato del correo electronico
  const email2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  // Si el valor del campo email dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.email indicando que el email no puede estar vacío.
  if (values.email === "") {
    error.email = "El email no puede estar vacio";
  } else if (!email2.test(values.email)) {
    error.email = "No encontramos el email";
  } else {
    error.email = "";
  }
  // Si el valor del campo contrasena dentro del objeto values está vacío, se asigna un mensaje de error al
  //campo error.contrasena indicando que el email no puede estar vacío.
  if (values.contrasena === "") {
    error.contrasena = "Escriba su contraseña";
  } else {
    error.contrasena = "";
  }

  return error;
}

export default validation;

/* Importamos los módulos y componentes necesarios para 
 Clases */
import React, { useEffect, useState } from "react";
import Menu from "../../components/Menu";
import axios from "axios";
import { Link, useNavigate } from "react-router-dom";

const Clases = () => {
  //Utilizamos useState y useEffect para reutilizar una solicitud GET actualizando
  //el estado con la respuesta

  const [data, setData] = useState([]);

  useEffect(() => {
    axios
      .get("https://backend-8t9t.onrender.com/clases")
      .then((res) => setData(res.data))
      .catch((err) => console.log(err));
  });
  //Maneja el evento para eliminar un elemento utilizando su parametro ID
  const navigate = useNavigate();
  const handleDelete = (id) => {
    axios
      .delete("https://backend-8t9t.onrender.com/deleteclases/" + id)
      .then((res) => navigate("/clases"))
      .catch((err) => console.log(err));
  };
  //Esta funcion se utiliza para formatear las fechas en un formato mas legible
  //para el usuario
  const formatDate = (dateString) => {
    const options = { day: "numeric", month: "numeric", year: "numeric" };
    const date = new Date(dateString);
    return date.toLocaleDateString(undefined, options);
  };
  //Esta funcion se utiliza para formatear las horas en un formato mas legible
  //para el usuario
  const formatTime = (timeString) => {
    return timeString.slice(0, -3); // Elimina los últimos 3 caracteres (los segundos)
  };
  return (
    //Representamos una tabla con información de las clases.
    //Cada fila de la tabla muestra los datos de una clase, incluyendo el nombre
    //del alumno, el nombre del profesor, la fecha, la hora, la marca del vehículo
    //y acciones como modificar y borrar la clase.
    <div className="table-responsive">
      <Menu>
        <h1 className="clases">
          Clases<br></br>
          <Link className="btn-a" to="/Anadirclases">
            Añadir +
          </Link>
        </h1>

        <table className="table">
          <thead>
            <tr>
              <th>Nombre Alumno</th>
              <th>Nombre Profesor</th>
              <th>Fecha</th>
              <th>Hora</th>
              <th>Vehículo</th>
              <th>Acción</th>
            </tr>
          </thead>
          <tbody>
            {data.map((d, i) => (
              <tr>
                <td data-titulo="Alumno">{d.nombreAlumno}</td>
                <td data-titulo="Profesor">{d.nombreProfesor}</td>
                <td data-titulo="fecha">{formatDate(d.fecha)}</td>
                <td data-titulo="Hora">{formatTime(d.hora)}</td>
                <td data-titulo="Marca">{d.marca}</td>
                <td>
                  {/* Crea un enlace que navega a la ruta cuando se hace clic. 
                           El `d.id` es la identificación de la
                           clase, donde navega a la página de actualización (editclases)
                           para esa clase (ID).*/}
                  <Link to={`/editclases/${d.id}`} className="btn-m">
                    Modificar
                  </Link>
                  {/* Crea un elemento de enlace que, al hacer clic en él, ejecutará la función `handleDelete` con
                            `d.id` como argumento. Esta función es responsable de enviar una
                            solicitud de ELIMINACIÓN a una URL específica con la ID dada, en este caso para eliminar
                            a una clase especifica (ID).*/}
                  <Link onClick={(e) => handleDelete(d.id)} className="btn-b">
                    Borrar
                  </Link>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </Menu>
    </div>
  );
};

export default Clases;

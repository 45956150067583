import React, { createContext, useState } from "react";

// Crea el contexto de usuario
const UserContext = createContext();
// Crea el proveedor de contexto
const UserProvider = ({ children }) => {
  // Define el estado del usuario y la función para actualizarlo
  const [user, setUser] = useState(null);
  // Agrega la propiedad loggedIn al estado
  const [loggedIn, setLoggedIn] = useState(false);
  // Retorna el proveedor de contexto con los valores del contexto
  return (
    <UserContext.Provider value={{ user, setUser, loggedIn, setLoggedIn }}>
      {children}
    </UserContext.Provider>
  );
};
//Exportar el contexto y el proveedor de contexto
export { UserContext, UserProvider };

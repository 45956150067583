/**
 * La función `validación` valida el
 * formulario verificando si ciertos campos están vacíos o si el campo
 *  de correo electrónico tiene el formato correcto. si esta vacio devolvera un mensaje
 * con el error
 *
 */
function validation(values) {
    let error = {};

    // Si el valor del campo fecha dentro del objeto values está vacía, se asigna un mensaje de error al
    //campo error.fecha indicando que la fecha no puede estar vacía.
    if (values.fecha === "") {
      error.fecha = "la fecha no puede estar vacia";
    }
     else {
      error.fecha = "";
    }
    // Si el valor del campo hora dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.hora indicando que el nombre no puede estar vacío.
    if (values.hora === "") {
      error.hora = "La hora no puede estar vacia";
    } else {
      error.hora = "";
    }
    // Si el valor del campo alumno dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.alumno_id indicando que la contrasena no puede estar vacía.
    if (values.alumno_id === "") {
      error.alumno_id = "El alumno no puede estar vacio";
    } else {
      error.alumno_id = "";
    }
    // Si el valor del campo profesor dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.profesor_id indicando que la direccion no puede estar vacía.
    if (values.profesor_id === "") {
      error.profesor_id = "El profesor no puede estar vacio";
    } else {
      error.profesor_id = "";
    }

     // Si el valor del campo vehiculo dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.vehiculo_id indicando que la direccion no puede estar vacía.
    if (values.vehiculo_id === "") {
        error.vehiculo_id = "El vehiculo no puede estar vacio";
      } else {
        error.vehiculo_id = "";
      }
  
    return error;
  }
  
  export default validation;
  
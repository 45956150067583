/**
 * La función `validación` valida el
 * formulario verificando si ciertos campos están vacíos o si el campo
 *  de correo electrónico tiene el formato correcto. si esta vacio devolvera un mensaje
 * con el error
 *
 */
function validation(values) {
    let error = {};
    //Definimos la expresion regular para validar el formato del correo electronico
    const email2 = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // Si el valor del campo email dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.email indicando que el email no puede estar vacío.
    if (values.email === "") {
      error.email = "El email no puede estar vacio";
    } else if (!email2.test(values.email)) {
      error.email = "No encontramos el email";
    } else {
      error.email = "";
    }
    // Si el valor del campo nombre dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.nombre indicando que el nombre no puede estar vacío.
    if (values.nombreAlumno === "") {
      error.nombreAlumno = "El nombre no puede estar vacio";
    } else {
      error.nombreAlumno = "";
    }
    // Si el valor del campo edad dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.edad indicando que la contrasena no puede estar vacía.
    if (values.edad === "") {
      error.edad = "la edad no puede estar vacia";
    } else {
      error.edad = "";
    }
    // Si el valor del campo direccion dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.direccion indicando que la direccion no puede estar vacía.
    if (values.direccion === "") {
      error.direccion = "La direccion no puede estar vacia";
    } else {
      error.direccion = "";
    }
  
    return error;
  }
  
  export default validation;
  
// Importamos los módulos y componentes necesarios
import React, { useState } from "react";
import Menu from "../../components/Menu";
import { useNavigate } from "react-router-dom";
import validation from "../../components/VehiculoValidation";

const Anadirvehiculos = () => {
  // Estados para almacenar los valores del formulario
  const [marca, setMarca] = useState("");
  const [ano, setAno] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Datos del vehículo a enviar al backend
    const vehiculoData = {
      marca,
      ano,
    };
    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(vehiculoData));
    // Verificar si no hay errores de validación
    if (
    marca !== "" && 
    ano !== ""
    ) {
      submitForm();
    }
  };
  const submitForm = async () => {
      try {
        // Enviar el formulario a través de fetch
        const response = await fetch(
          "https://backend-8t9t.onrender.com/AnadirVehiculos",
          {
            method: "POST",
            headers: {
              "Content-Type": "application/json",
            },
            body: JSON.stringify({
              marca,
              ano,
          }),
        }
        );

        if (response.ok) {
          // Limpiar los campos del formulario
          setMarca("");
          setAno("");

          navigate("/vehiculos");
          alert("Vehiculo añadido correctamente");
        } else {
          console.log("Error al enviar el formulario");
          alert("Error al enviar el formulario");
        }
      } catch (error) {
        console.log(error);
        alert("Error al enviar el formulario");
      }
    };

  return (
    <Menu>
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Añadir Vehiculo</h4>
          {/* Campo para la marca */}
          <input
            className="controls"
            type="text"
            name="marca"
            id="marca"
            placeholder="Ingrese la marca"
            value={marca}
            onChange={(e) => setMarca(e.target.value)}
          />
          <div className="errores">{errors.marca && errors.marca}</div>
          {/* Campo para el año */}
          <input
            className="controls"
            type="number"
            name="ano"
            id="ano"
            placeholder="Ingrese el año"
            value={ano}
            onChange={(e) => setAno(e.target.value)}
          />
          <div className="errores">{errors.ano && errors.ano}</div>
          {/* Botón de envío del formulario */}
          <input className="botons" type="submit" value="Añadir" />
        </form>
      </section>
    </Menu>
  );
};

export default Anadirvehiculos;

// Importamos los módulos y componentes necesarios
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import validation from "../components/SignupValidation";

const Signup = () => {
  // Estados para almacenar los valores del formulario
  const [nombreProfesor, setNombreProfesor] = useState("");
  const [email, setEmail] = useState("");
  const [contrasena, setContrasena] = useState("");
  const [especialidad, setEspecialidad] = useState("");

  // Hook useNavigate para la navegación programática
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Datos del profesor a enviar al backend
    const profesorData = {
      nombreProfesor,
      email,
      contrasena,
      especialidad
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(profesorData));

    // Verificar si no hay errores de validación
    if (
      errors.nombreProfesor === "" &&
      errors.email === "" &&
      errors.contrasena === "" &&
      errors.especialidad === ""
    ) {
      try {
        // Enviar el formulario a través de fetch
        const response = await fetch("https://backend-8t9t.onrender.com/signup", {
          method: "POST",
          headers: {
            "Content-Type": "application/json"
          },
          body: JSON.stringify(profesorData)
        });

        if (response.ok) {
          // Limpiar los campos del formulario
          setNombreProfesor("");
          setEmail("");
          setContrasena("");
          setEspecialidad("");

          navigate("/");
          alert("Profesor añadido correctamente");
        } else {
          console.log("Error al enviar el formulario");
          alert("Error al enviar el formulario");
        }
      } catch (error) {
        console.log(error);
        alert("Error al enviar el formulario");
      }
    }
  };

  return (
    <div className="login-container">
      <div className="login-info-container">
        <br />
        <br />
        <h1 className="title">Registrarse</h1>
        <form className="inputs-container" onSubmit={handleSubmit}>
          <br />
          {/* Campo para el nombre */}
          <input
            type="text"
            placeholder="Escribe tu nombre"
            name="nombreProfesor"
            id="nombrePorfesor"
            value={nombreProfesor}
            onChange={(e) => setNombreProfesor(e.target.value)}
            className="form-control"
          />
          <div className="errores">{errors.nombreProfesor && errors.nombreProfesor}</div>
          <br />
          {/* Campo para el email */}
          <input
            type="text"
            placeholder="Enter email"
            name="email"
            id="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            className="form-control"
          />
          <div className="errores">{errors.email && errors.email}</div>
          <br />
          {/* Campo para la contraseña */}
          <input
            type="password"
            placeholder="Enter contrasena"
            name="contrasena"
            id="contrasena"
            value={contrasena}
            onChange={(e) => setContrasena(e.target.value)}
            className="form-control rounded-0"
          />
          <div className="errores">
            {errors.contrasena && errors.contrasena}
          </div>
          <br />
          {/* Campo para la especialidad */}
          <input
            type="text"
            placeholder="Enter especialidad"
            name="especialidad"
            id="especialidad"
            value={especialidad}
            onChange={(e) => setEspecialidad(e.target.value)}
            className="form-control"
          />
          <div className="errores">
            {errors.especialidad && errors.especialidad}
          </div>
          <br />
          <br />
          {/* Botón de envío del formulario */}
          <button type="submit" className="btn">
            Crear cuenta
          </button>
          <br />
          <br />
          {/* Enlace para iniciar sesión */}
          <Link to="/" className="btn2">
            Iniciar Sesión
          </Link>
        </form>
      </div>
      <img className="image-container" src="/img/alumnos.png" alt="" />
    </div>
  );
};

export default Signup;
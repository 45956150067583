// Importamos los módulos necesarios
import React, { useState } from "react";
import Menu from "../../components/Menu";
import { useNavigate } from "react-router-dom";
import validation from "../../components/AlumnoValidation";

const CreateAlumno = () => {
  // Estados para almacenar los valores del formulario
  const [nombreAlumno, setNombre] = useState("");
  const [edad, setEdad] = useState("");
  const [email, setEmail] = useState("");
  const [direccion, setDireccion] = useState("");
  const navigate = useNavigate();
  const [errors, setErrors] = useState({});

  const handleSubmit = async (event) => {
    event.preventDefault();

    // Crea un objeto alumnoData con propiedades cuyos valores
    // son extraídos de las variables nombreAlumno, edad, email y direccion
    const alumnoData = {
      nombreAlumno,
      edad,
      email,
      direccion,
    };

    // Validación de los datos del formulario utilizando la función validation
    setErrors(validation(alumnoData));

    // Verificar si no hay errores de validación
    if (
      nombreAlumno !== "" &&
      edad !== "" &&
      email !== "" &&
      direccion !== ""
    ) {
      // Enviar el formulario
      submitForm();
    }
  };

  const submitForm = async () => {
    try {
      // Realiza una solicitud POST al backend para crear un alumno
      const response = await fetch("https://backend-8t9t.onrender.com/create", {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          nombreAlumno,
          edad,
          email,
          direccion,
        }),
      });

      if (response.ok) {
        // Limpiar los campos del formulario
        setNombre("");
        setEdad("");
        setEmail("");
        setDireccion("");

        // Navegar a la página de lista de alumnos
        navigate("/alumnos");

        // Mostrar una alerta indicando que el alumno se añadió correctamente
        alert("Alumno añadido correctamente");
      } else {
        console.log("Error al enviar el formulario");
        alert("Error al enviar el formulario");
      }
    } catch (error) {
      console.log(error);
      alert("Error al enviar el formulario");
    }
  };

  return (
    <Menu>
      <section className="create">
        <form onSubmit={handleSubmit}>
          <h4>Añadir alumno</h4>
          {/* Campo nombreAlumno */}
          <input
            className="controls"
            type="text"
            name="nombreAlumno"
            id="nombreAlumno"
            placeholder="Ingrese su Nombre"
            value={nombreAlumno}
            onChange={(e) => setNombre(e.target.value)}
          />
          <div className="errores">
            {errors.nombreAlumno && errors.nombreAlumno}
          </div>
          {/* Campo edad */}
          <input
            className="controls"
            type="number"
            name="edad"
            id="edad"
            placeholder="Ingrese su edad"
            value={edad}
            onChange={(e) => setEdad(e.target.value)}
          />
          <div className="errores">{errors.edad && errors.edad}</div>
          {/* Campo email */}
          <input
            className="controls"
            type="email"
            name="email"
            id="email"
            placeholder="Ingrese su Email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
          <div className="errores">{errors.email && errors.email}</div>
          {/* Campo direccion */}
          <input
            className="controls"
            type="text"
            name="direccion"
            id="direccion"
            placeholder="Ingrese su Direccion"
            value={direccion}
            onChange={(e) => setDireccion(e.target.value)}
          />
          <div className="errores">
            {errors.direccion && errors.direccion}
          </div>
          <input className="botons" type="submit" value="Añadir" />
        </form>
      </section>
    </Menu>
  );
};

export default CreateAlumno;
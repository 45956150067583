/**
 * La función `validación` valida el
 * formulario verificando si ciertos campos están vacíos o si el campo
 *  de correo electrónico tiene el formato correcto. si esta vacio devolvera un mensaje
 * con el error
 *
 */
function validation(values) {
    let error = {};
    
     // Si el valor del campo marca dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.marca indicando que el nombre no puede estar vacío.
    if (values.marca === "") {
      error.marca = "la marca no puede estar vacia";
    } else {
      error.marca = "";
    }
    // Si el valor del campo año dentro del objeto values está vacío, se asigna un mensaje de error al
    //campo error.ano indicando que el nombre no puede estar vacío.
    if (values.ano === "") {
      error.ano = "El año no puede estar vacio";
    } else {
      error.ano = "";
    }
   

    return error;
  }
  
  export default validation;
  
/* Importamos iconos de la bibioteca react*/
import {
  FaTh,
  FaUserGraduate,
  FaUserPlus,
  FaUserTie,
  FaRegAddressCard,
  FaCalendarPlus,
  FaCar,
  FaPlusCircle,
  FaPowerOff,
} from "react-icons/fa";

/*Incorporamos el componente de NavLink para poder navegar de 
react-router-dom*/
import { NavLink } from "react-router-dom";

/* Definimos Menu, donde incorporamos la matriz menuItem donde contienen
todos los objetos que representan el menu, con sus respectivas propiedades
su ruta, su nombre y su icono*/

const Menu = ({ children }) => {
  const menuItem = [
    {
      path: "/about",
      name: "Inicio",
      icon: <FaTh />,
    },
    {
      path: "/alumnos",
      name: "Alumnos",
      icon: <FaUserGraduate />,
    },
    {
      path: "/create",
      name: "Añadir Alumno",
      icon: <FaUserPlus />,
    },
    {
      path: "/profesores",
      name: "Profesores",
      icon: <FaUserTie />,
    },
    {
      path: "/clases",
      name: "Clases",
      icon: <FaRegAddressCard />,
    },
    {
      path: "/Anadirclases",
      name: "Clases",
      icon: <FaCalendarPlus />,
    },
    {
      path: "/vehiculos",
      name: "Vehiculos",
      icon: <FaCar />,
    },
    {
      path: "/Anadirvehiculos",
      name: "Vehiculos",
      icon: <FaPlusCircle />,
    },
    {
      path: "/",
      name: "Cerrar",
      icon: <FaPowerOff />,
    },
  ];
  /* Devolvemos el codigo JSX que nos permite
   incorporar el HTML y mapea los elementos de MenuItems*/
  return (
    <div className="container">
      <div className="sidebar">
        <div className="top_section"></div>

        {menuItem.map((item, index) => (
          <NavLink
            to={item.path}
            key={index}
            className="link"
            activeClassName="active"
          >
            <div className="icon">{item.icon}</div>
          </NavLink>
        ))}
      </div>
      <main>{children}</main>
    </div>
  );
};

export default Menu;
